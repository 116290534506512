const tabKeyHandler = (e: any, tabStartElement: HTMLElement | null) => {
    var TABKEY = 9;
    if (e.keyCode === TABKEY) {
        if (e.preventDefault) {
            e.preventDefault();
        }
        tabStartElement?.focus();
        return false;
    }
}

export const onOpenModal = (modalId: string, startPointId: string, lastPointId: string, tabStartPointId: string) => {
    (window as any).lastActiveElement = document.activeElement;
    if (startPointId !== "") {
        setTimeout(() => {
            let startElement = document.getElementById(startPointId);
            if (startElement) {
                startElement.focus();
            }
            let lastElement: any = document.getElementById(lastPointId);
            let tabStartElement = document.getElementById(tabStartPointId);
            if (lastElement && tabStartElement) {
                if (lastElement.addEventListener) {
                    lastElement.addEventListener('keydown', ((e: any) => { tabKeyHandler(e, tabStartElement) }), false);
                } else if (lastElement.attachEvent) {
                    lastElement.attachEvent('onkeydown', ((e: any) => { tabKeyHandler(e, tabStartElement) }));
                }
            }
        }, 1000);
    }
}

export const onCloseModal = (modalId: string, isFocusLastActive: boolean) => {
    if (isFocusLastActive) {
        focusLastActiveElement();
    }
}

export const focusLastActiveElement = () => {
    let lastActiveElement = (window as any).lastActiveElement;
    if (lastActiveElement) {
        lastActiveElement.focus();
    }
}

export const focusElement = (id: string, isClass: boolean) => {
    let element: any = null;
    if (isClass) {
        element = document.getElementsByClassName(id)[0];
    } else {
        element = document.getElementById(id);
    }
    if (element) {
        element.focus();
    }
}

export const addRemoveSearchOverlay = (isAdd: boolean) => {
    let body = document.getElementById("body");
    if (body) {
        if (isAdd) {
            body.classList.add("search-overlay");
        } else {
            body.classList.remove("search-overlay");
        }
    }
}