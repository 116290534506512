import { createSlice } from "@reduxjs/toolkit";

interface LoaderState {
  isPreLoaderShow: boolean;
  isContentLoaderShow: boolean;
}

const initialState: LoaderState = {
  isPreLoaderShow: false,
  isContentLoaderShow: false,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    showPreLoader: (state?: LoaderState) => {
      if (state) state.isPreLoaderShow = true;
    },
    hidePreLoader: (state?: LoaderState) => {
      if (state) state.isPreLoaderShow = false;
    },
    showContentLoader: (state?: LoaderState) => {
      if (state) state.isContentLoaderShow = true;
    },
    hideContentLoader: (state?: LoaderState) => {
      if (state) state.isContentLoaderShow = false;
    },
  },
});

export const {
  showPreLoader,
  hidePreLoader,
  showContentLoader,
  hideContentLoader,
} = loaderSlice.actions;
