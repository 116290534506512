import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import searchApiRequest from "../../../../app/api/facilities/searchApiRequest";
import { PopularPlaces } from "../../../../app/models/popularPlaces";
import { store } from "../../../../app/store/configureStore";
import { hidePreLoader, showContentLoader, hideContentLoader } from "../../../loader/loaderSlice";
import navigationService from "../../../../app/history/history";
import { getPlaceAlerts } from "../facilities/facilitiesSlice";
import { getNearByPopularPlaces } from "../parks/parksSlice";
import { setFocusToElement, onFacilityLoad, formatDate } from "../../../../app/util/util";

interface SearchState {
    popularPlaces: PopularPlaces[],
    filters: any | null,
    selectedPlace: any | null,
    searchData: any,
    placeData: any,
    isFromCamping: boolean,
    searchStep: string,
    searchAllDataStep: string,
    searchAllDataVisitedStep: string[]
}

const initialState: SearchState = {
    popularPlaces: [],
    filters: null,
    selectedPlace: null,
    searchData: {
        startDate: null,
        endDate: null,
        nights: 0,
        unitCategoryId: null,
        sleepingUnitId: null,
        minVehicleLength: null,
        unitTypesGroupIds: [],
        isADA: false,
        amenitiesIds: [],
        isAdditionalFilterShow: false,
        sort: "",
        unitSortBy: ""
    },
    placeData: null,
    isFromCamping: false,
    searchStep: "home",
    searchAllDataStep: "date",
    searchAllDataVisitedStep: ['location', 'date'],
}

const defaultPlaceSearchData = {
    "InSeasonOnly": true,
    "WebOnly": true,
    "NearbyCountLimit": 10,
    "NearbyOnlyAvailable": false,
    "CountNearby": true,
    "CountUnits": true,
    "HighlightedPlaceId": 0
};

export const getPopularPlace = createAsyncThunk<PopularPlaces[], { limit: number }>(
    'search/getPopularPlace',
    async ({ limit }, thunkAPI) => {
        try {
            const popularPlace = await searchApiRequest.getPopularPlaces(limit);
            popularPlace.forEach((element: any) => {
                element.Name = element.PlaceName;
                element.CityParkId = element.PlaceId;
            });
            return popularPlace;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export async function getPlaceSuggestion(searchText: string, callback: any) {
    const suggestions = await searchApiRequest.getPlaceSuggestion(searchText);
    if (suggestions && suggestions.length > 0) {
        callback(suggestions);
    }
    else {
        callback([]);
    }
}


export const getCityPark = async (placeId: any, callback: any) => {
    const parkInfo = await searchApiRequest.getCityPark(placeId);
    if (parkInfo && parkInfo.length > 0) {
        callback(parkInfo);
    }
    else {
        callback([]);
    }
}
export const getPlaceFilters = createAsyncThunk<any, { placeId: any }>(
    'search/getPlaceFilters',
    async ({ placeId }, thunkAPI) => {
        try {
            const filtersResponse = await searchApiRequest.getPlaceFilters(placeId);
            let filters = Object.assign({}, filtersResponse);
            if (filters && filters.AllAmenity && filters.AllAmenity.length > 0) {
                let amenity = filters.AllAmenity.filter((ele: any) => { return ele.IsSearchable && ele.AmenityType === 0 });
                amenity = amenity.sort((a: any, b: any) => { return a.Name - b.Name });
                filters.AllAmenity = Object.assign([], amenity);
            }
            if (filters.MinVehicleLengths && filters.MinVehicleLengths.length > 0) {
                filters.MinVehicleLengths.forEach((ele: any) => {
                    ele.MinVehicleName = ele.MinVehicleName + " feet";
                });
            }
            let searchData: any = Object.assign({}, store.getState().search.searchData);
            searchData.amenitiesIds = [...searchData.amenitiesIds];
            searchData.unitTypesGroupIds = [...searchData.unitTypesGroupIds];
            searchData.amenitiesIds.forEach((ele: any, index: any) => {
                let selectedData = filters.AllAmenity.find((e: any) => { return e.AmenityId.toString() == ele.toString() });
                if (!selectedData) {
                    searchData.amenitiesIds[index] = null;
                }
            });
            if (searchData.amenitiesIds.length > 0) {
                searchData.amenitiesIds = searchData.amenitiesIds.filter((ele: any) => { return ele !== null });
            }
            if (searchData.unitCategoryId) {
                let unitCategory = filters.UnitCategories.find((ele: any) => { return ele.UnitCategoryId == searchData.unitCategoryId });
                if (unitCategory) {
                    if (searchData.sleepingUnitId) {
                        let sleepingUnit = filters.NightlySleepingUnits.find((ele: any) => { return ele.SleepingUnitId == searchData.sleepingUnitId });
                        if (sleepingUnit) {
                            if (searchData.minVehicleLength) {
                                let minVehicleLength = filters.MinVehicleLengths.find((ele: any) => { return ele.MinVehicleLength == searchData.minVehicleLength });
                                if (!minVehicleLength) {
                                    searchData.minVehicleLength = null;
                                }
                            } else {
                                searchData.minVehicleLength = null;
                            }
                        } else {
                            searchData.sleepingUnitId = null;
                            searchData.minVehicleLength = null;
                        }
                    } else {
                        searchData.sleepingUnitId = null;
                        searchData.minVehicleLength = null;
                    }
                    if (searchData.unitTypesGroupIds && searchData.unitTypesGroupIds.length > 0) {
                        searchData.unitTypesGroupIds.forEach((ele: any, index: any) => {
                            let selectedData = filters.UnitTypesGroups.find((e: any) => { return e.UnitTypesGroupId == ele.toString() });
                            if (!selectedData) {
                                searchData.unitTypesGroupIds[index] = null;
                            }
                        });
                        searchData.unitTypesGroupIds = searchData.unitTypesGroupIds.filter((ele: any) => { return ele !== null });
                        searchData.isAdditionalFilterShow = (searchData.unitTypesGroupIds && searchData.unitTypesGroupIds.length > 0) ? true : false;
                    } else if (filters.UnitTypesGroups.filter((ele: any) => { return ele.UnitCategoryId.toString() === searchData.unitCategoryId.toString() }).length > 0) {
                        searchData.unitTypesGroupIds = [];
                        searchData.isAdditionalFilterShow = true;
                    } else {
                        searchData.unitTypesGroupIds = [];
                        searchData.isAdditionalFilterShow = false;
                    }
                    thunkAPI.dispatch(setSearchData(searchData));
                } else {
                    searchData.unitCategoryId = null;
                    searchData.sleepingUnitId = null;
                    searchData.minVehicleLength = null;
                    searchData.unitTypesGroupIds = [];
                    searchData.isAdditionalFilterShow = false;
                    thunkAPI.dispatch(setSearchData(searchData));
                }
            } else {
                searchData.unitCategoryId = null;
                searchData.sleepingUnitId = null;
                searchData.minVehicleLength = null;
                searchData.unitTypesGroupIds = [];
                searchData.isAdditionalFilterShow = false;
                thunkAPI.dispatch(setSearchData(searchData));
            }
            return { filters: filters, searchData: searchData };
        } catch (error: any) {
            console.log(error)
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const searchPlace = createAsyncThunk<any, { searchData: any, selectedPlace: any, isCallNearBy: any, isPreLoaderShow: any, isAlertCheck: any }>(
    'search/searchPlace',
    async ({ searchData, selectedPlace, isCallNearBy, isPreLoaderShow, isAlertCheck }, thunkAPI) => {
        if (isCallNearBy) {
            thunkAPI.dispatch(setPlaceData(null));
        }
        if (!isPreLoaderShow) {
            thunkAPI.dispatch(showContentLoader());
        }
        try {
            let webSiteSettings = store.getState().common.webSiteSettings;
            let parkSearchData: any = {
                "PlaceId": selectedPlace.CityParkId === 0 ? webSiteSettings?.facility_default_place_id : selectedPlace.PlaceId,
                "Latitude": selectedPlace.Latitude ? selectedPlace.Latitude : 34.2570034764866,
                "Longitude": selectedPlace.Longitude ? selectedPlace.Longitude : -114.162234470524,
                "Nights": searchData.nights ? searchData.nights : 1,
                "CustomerId": 0,
                "StartDate": formatDate(searchData.startDate, store.getState().common.applicationSettings.apiDateFormat),
                "UnitCategoryId": (searchData.unitCategoryId) ? parseInt(searchData.unitCategoryId) : 0,
                "SleepingUnitId": (searchData.sleepingUnitId) ? parseInt(searchData.sleepingUnitId) : 0,
                "MinVehicleLength": (searchData.minVehicleLength) ? parseInt(searchData.minVehicleLength) : 0,
                "UnitTypesGroupIds": searchData.unitTypesGroupIds ? searchData.unitTypesGroupIds : null,
                "AmenityIds": searchData.amenitiesIds ? searchData.amenitiesIds : null,
                "Sort": searchData.sort ? (searchData.sort.toLowerCase() === "availability" ? "name" : searchData.sort.toLowerCase()) : store.getState().common.applicationSettings.defaultParkListSort,
                "IsADA": searchData.isADA ? searchData.isADA : false,
                "RestrictADA": searchData.isADA ? searchData.isADA : false,
                "NearbyLimit": (webSiteSettings?.default_place_results_range != null ? webSiteSettings.default_place_results_range : 100),
                "isSearchAllParks": selectedPlace.CityParkId === 0 ? true : false,
                "customerClassificationId": store.getState().authentication.customerDetail?.customerClassificationId ? store.getState().authentication.customerDetail?.customerClassificationId : 0
            };
            let mergedData = { ...parkSearchData, ...defaultPlaceSearchData };
            const data = await searchApiRequest.searchPlace(mergedData);
            if (data.SelectedPlace) {
                const availableFacility: any[] = [];
                const unAvailableFacility: any[] = [];
                const otherFacility: any[] = [];
                let facilities = (data.SelectedPlace.Facilities) ? Object.values(data.SelectedPlace.Facilities) : [];
                if (facilities.length > 0) {
                    facilities.forEach((element: any) => {
                        let unitTypes = (element.UnitTypes) ? Object.values(element.UnitTypes) : [];
                        let availableSites: any = 0;
                        if (unitTypes && unitTypes.length > 0) {
                            availableSites = unitTypes.reduce(function (previousValue: any, currentValue: any) {
                                return previousValue + currentValue.AvailableCount;
                            }, 0);
                        }
                        if (element.Available && element.AvailableFiltered && availableSites > 0 && (element.Restrictions.IsRestrictionValid === true || element.Restrictions.IsRestrictionValid === null || element.Restrictions.IsRestrictionValid === undefined)) {
                            element.AvailableSites = availableSites;
                            availableFacility.push(element);
                        } else if (element.Available && !element.AvailableFiltered && availableSites > 0 && (element.Restrictions.IsRestrictionValid === true || element.Restrictions.IsRestrictionValid === null || element.Restrictions.IsRestrictionValid === undefined)) {
                            element.AvailableSites = availableSites;
                            otherFacility.push(element);
                        } else {
                            unAvailableFacility.push(element);
                        }
                    });
                }
                data.SelectedPlace.AvailableFacilities = availableFacility ? availableFacility : null;
                data.SelectedPlace.UnAvailableFacilities = unAvailableFacility;
                data.SelectedPlace.OtherFacilities = otherFacility;
            }
            if (data.NearbyPlaces && data.NearbyPlaces.length > 0) {
                if (searchData.sort.toLowerCase() === 'availability') {
                    let sortByAvailableCount = data.NearbyPlaces.sort((a: any, b: any) => { return b.AvailableUnitCount - a.AvailableUnitCount });
                    sortByAvailableCount = sortByAvailableCount.sort((a: any, b: any) => { return (a.AvailableUnitCount == b.AvailableUnitCount) ? a.Name - b.Name : "" })
                    data.NearbyPlaces = Object.assign([], sortByAvailableCount);
                }
                else if (searchData.sort.toLowerCase() === 'distance') {
                    let availablePlace = data.NearbyPlaces.filter((ele: any) => { return ele.Available });
                    let notAvailablePlace = data.NearbyPlaces.filter((ele: any) => { return !ele.Available });
                    if (availablePlace.length > 0 && notAvailablePlace.length > 0) {
                        availablePlace = availablePlace.sort((a: any, b: any) => a.MilesFromSelected - b.MilesFromSelected);
                        notAvailablePlace = notAvailablePlace.sort((a: any, b: any) => a.MilesFromSelected - b.MilesFromSelected);
                        data.NearbyPlaces = availablePlace.concat(notAvailablePlace);
                    } else if (availablePlace.length > 0) {
                        data.NearbyPlaces = availablePlace.sort((a: any, b: any) => a.MilesFromSelected - b.MilesFromSelected);
                    } else {
                        data.NearbyPlaces = notAvailablePlace.sort((a: any, b: any) => a.MilesFromSelected - b.MilesFromSelected);
                    }
                }
            }
            if (isPreLoaderShow) {
                thunkAPI.dispatch(hidePreLoader());
            } else {
                thunkAPI.dispatch(hideContentLoader());
            }
            if (data.SelectedPlace == null && data.NearbyPlaces == null) {
                navigationService.navigation("/placenotfound");
            } else {
                if (isAlertCheck && data.SelectedPlace.HasAlerts) {
                    thunkAPI.dispatch(getPlaceAlerts({ placeId: data.SelectedPlace.PlaceId }));
                }
                sessionStorage.setItem("selectedPlaceId", data.SelectedPlace.PlaceId);
                thunkAPI.dispatch(setPlaceData({ ...data, isSearchAllParks: parkSearchData.isSearchAllParks }));
                if (isCallNearBy) {
                    thunkAPI.dispatch(getNearByPopularPlaces({ searchData, selectedPlace: data.SelectedPlace }));
                }
                if (store.getState().common.activeRoute === "parks") {
                    if (store.getState().search.isFromCamping) {
                        setFocusToElement("search-header-combobox", false);
                        setTimeout(() => {
                            thunkAPI.dispatch(setIsFromCamping(false));
                        }, 2000);
                    } else {
                        setFocusToElement("result-start-point", false);
                    }
                } else if (store.getState().common.activeRoute === "facilities") {
                    let allExpandedCard = document.getElementsByClassName("facilities-dropdown-box");
                    if (allExpandedCard && allExpandedCard.length > 0) {
                        for (let ele of allExpandedCard) {
                            ele.classList.add("hidden");
                            let id = ele.id?.split("next-available-box-")[1];
                            let linkId = document.getElementById("next-available-box-link-" + id);
                            linkId?.classList.remove("active");
                            linkId?.classList.add("Inactive");
                            linkId?.setAttribute("aria-expanded", "false");
                            linkId?.setAttribute("aria-label", "Show next available date");
                        }
                    }
                    onFacilityLoad();
                }
            }
            return;
        } catch (error: any) {
            if (isPreLoaderShow) {
                thunkAPI.dispatch(hidePreLoader());
            } else {
                thunkAPI.dispatch(hideContentLoader());
            }
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)



export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSelectedPlace: (state, action) => {
            state.selectedPlace = action.payload;
        },
        setPlaceData: (state, action) => {
            state.placeData = action.payload;
        },
        setSearchData: (state, action) => {
            if (action.payload.startDate && action.payload.endDate && action.payload.nights) {
                sessionStorage.setItem("selectedStartdate", action.payload.startDate);
                sessionStorage.setItem("selectedEnddate", action.payload.endDate);
                sessionStorage.setItem("selectedNights", action.payload.nights);
            }
            state.searchData = action.payload;
        },
        setIsFromCamping: (state, action) => {
            state.isFromCamping = action.payload;
        },
        setSearchStep: (state, action) => {
            state.searchStep = action.payload;
        },
        setSearchAllDataStep: (state, action) => {
            state.searchAllDataStep = action.payload;
        },
        setSearchAllDataVisitedStep: (state, action) => {
            state.searchAllDataVisitedStep = action.payload;
        },
    },
    extraReducers: (builder => {
        builder.addCase(getPopularPlace.rejected, (state) => {
            state.popularPlaces = [];
        });
        builder.addCase(getPlaceFilters.rejected, (state) => {
            state.filters = null;
        });
        builder.addCase(searchPlace.rejected, (state) => {
            state.placeData = null;
        });
        builder.addMatcher(isAnyOf(getPopularPlace.fulfilled), (state, action) => {
            state.popularPlaces = action.payload;
        });
        builder.addMatcher(isAnyOf(getPlaceFilters.fulfilled), (state, action) => {
            state.filters = action.payload.filters;
        });
    })
})
export const { setSelectedPlace, setPlaceData, setSearchData, setIsFromCamping, setSearchStep, setSearchAllDataStep, setSearchAllDataVisitedStep } = searchSlice.actions;