import { ApplicationCustomerDetail, ApplicationFeeRequest, ApplicationPassInformationRequest, ApplicationPermitOperationRequest, ApplicationQuestionAnswerRequest, CustomerApplicationDataRequest, CustomerApplicationDocumentsRequest, CustomerApplicationFinalizeDetailsRequest, CustomerPassApplicationRequest, DeleteCustomerApplicationDocumentsRequest, DocumentListByApplicationRequest, SaveApplicationQuestionAnswerRequest } from "../../models/application/application";
import { authorizedRDRequests, authorizedRDRequestsFileUpload } from "../httpRequest";

const applicationApiRequest = {
  getApplicationPasses: (customerId : number) => authorizedRDRequests.get(`WebAccessApplication/GetApplicationPasses?customerId=${customerId}`),
  getCustomerApplication: (request: ApplicationPassInformationRequest) => authorizedRDRequests.post(`WebAccessApplication/GetCustomerApplication`, request),
  getApplicationDescription: (applicationId: number) => authorizedRDRequests.get(`WebAccessApplication/GetApplicationDescription?applicationId=${applicationId}`),
  getDetailForApplicationPermitDate: (customerApplicationId: number) => authorizedRDRequests.get(`WebAccessApplication/GetDetailForApplicationPermitDate?customerApplicationId=${customerApplicationId}`),
  getPermitStartEndDate: (passId: number) => authorizedRDRequests.get(`WebAccessApplication/GetPermitStartEndDate?passId=${passId}`),
  checkCustomerPassApplicationExists: (request: CustomerPassApplicationRequest) => authorizedRDRequests.post(`WebAccessApplication/CheckCustomerPassApplicationExists`, request),
  saveCustomerApplicationData: (request: CustomerApplicationDataRequest) => authorizedRDRequests.post(`WebAccessApplication/SaveCustomerApplicationData`, request),
  getApplicationGlobalInputField: (applicationId: number) => authorizedRDRequests.get(`WebAccessApplication/GetApplicationGlobalInputField?applicationId=${applicationId}`),
  getApplicationQuestionAnswer: (request: ApplicationQuestionAnswerRequest) => authorizedRDRequests.post(`WebAccessApplication/GetApplicationQuestionAnswer`, request),
  getApplicationQuestion: (applicationId: number) => authorizedRDRequests.get(`WebAccessApplication/GetApplicationQuestion?applicationId=${applicationId}`),
  saveApplicationCustomerInfo: (request: ApplicationCustomerDetail) => authorizedRDRequests.post(`WebAccessApplication/SaveApplicationCustomerInfo`, request),
  saveApplicationQuestionAnswer: (request: SaveApplicationQuestionAnswerRequest) => authorizedRDRequests.post(`WebAccessApplication/SaveApplicationQuestionAnswer`, request),
  getDocumentListByApplication: (request: DocumentListByApplicationRequest) => authorizedRDRequests.post(`WebAccessApplication/GetDocumentListByApplication`, request),
  deleteCustomerApplicationDocuments: (request: DeleteCustomerApplicationDocumentsRequest) => authorizedRDRequests.get(`WebAccessApplication/DeleteCustomerApplicationDocuments?customerApplicatonId=${request.CustomerApplicatonId}&documentID=${request.DocumentID}`),
  manageDocumentsToEnigmaVault: (request: any) => authorizedRDRequestsFileUpload.post(`WebAccessApplication/ManageDocumentsToEnigmaVault`, request),
  saveApplicationDocuments: (request: CustomerApplicationDocumentsRequest) => authorizedRDRequests.post(`WebAccessApplication/SaveApplicationDocuments`, request),
  getApplicationFee: (request: ApplicationFeeRequest) => authorizedRDRequests.get(`WebAccessApplication/GetApplicationFee?applicationId=${request.ApplicationId}&classificationId=${request.ClassificationId}`),
  finalizeApplication: (request: CustomerPassApplicationRequest) => authorizedRDRequests.post(`WebAccessApplication/FinalizeApplication`, request),
  addApplicationToShoppingCart: (request: ApplicationPermitOperationRequest) => authorizedRDRequests.post(`WebAccessApplication/AddApplicationToShoppingCart`, request),
  applicationAddToCart: (request: ApplicationPermitOperationRequest) => authorizedRDRequests.post(`WebAccessApplication/ApplicationAddToCart`, request),
  getCustomerApplicationFinalizeDetails: (request: CustomerApplicationFinalizeDetailsRequest) => authorizedRDRequests.get(`WebAccessApplication/GetCustomerApplicationFinalizeDetails?customerApplicationId=${request.CustomerApplicationId}&referenceNo=${request.ReferenceNo}`),
  getAllApplicationStatusDate: (customerApplicationId: number) => authorizedRDRequests.get(`WebAccessApplication/getAllApplicationStatusDate?customerApplicationId=${customerApplicationId}`)
}

export default applicationApiRequest;