import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showPreLoader, hidePreLoader } from "../../../loader/loaderSlice";
import preCartApiRequest from "../../../../app/api/facilities/preCartApiRequest";
import { setAlertMessage } from "../../../common/settingsSlice";
import navigationService from "../../../../app/history/history";
import { onItemAddInCart } from "../../../customers/shoppingCartSlice";
import { store } from "../../../../app/store/configureStore";
import { replaceBRTag } from '../../../../app/util/util';

interface FacilityPreCartState {
    facilityPreCartDetail: any
}

const initialState: FacilityPreCartState = {
    facilityPreCartDetail: null
}

export const getPreCartDataForBookingModify = createAsyncThunk<any, { requestData: any }>(
    'facilityPreCart/getPreCartDataForBookingModify',
    async ({ requestData }, thunkAPI) => {
        try {
            const facilityPreCartDetail = store.getState().facilityPreCart.facilityPreCartDetail;
            thunkAPI.dispatch(showPreLoader());
            thunkAPI.dispatch(setFacilityPreCartDetail(null));
            const response = await preCartApiRequest.getPreCartDataForBookingModify(requestData);
            thunkAPI.dispatch(hidePreLoader());
            if (!response.IsCheckoutButtonShow) {
                if (response.ErrorMessages && response.ErrorMessages !== "") {
                    thunkAPI.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Message", message: replaceBRTag(response.ErrorMessages) }));
                }
                if (!requestData.isFromNightChange) {
                    thunkAPI.dispatch(setFacilityPreCartDetail(response));
                    return response;
                } else {
                    thunkAPI.dispatch(setFacilityPreCartDetail(requestData.isSelectedNewUnit ? response : (facilityPreCartDetail ? Object.assign({}, facilityPreCartDetail) : null)));
                    return null;
                }
            } else {
                thunkAPI.dispatch(setFacilityPreCartDetail(response));
                return response;
            }
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            thunkAPI.dispatch(setFacilityPreCartDetail(null));
            return null;
        }
    }
)

export const submitPreCartDataForBookingModify = createAsyncThunk<boolean, { requestData: any }>(
    'facilityPreCart/submitPreCartDataForBookingModify',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const response = await preCartApiRequest.submitPreCartDataForBookingModify(requestData);
            if (requestData.isModifyPreCart && !response.IsModifyTenderCart) {
                thunkAPI.dispatch(hidePreLoader());
                navigationService.navigation("/Customers/ShoppingCart");
                thunkAPI.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Message", message: response.ErrorMessage }));
            } else {
                const isSuccess = onItemAddInCart(thunkAPI.dispatch, response, null);
                if (isSuccess) {
                    localStorage.removeItem("preCartData");
                    localStorage.removeItem("vehicleData");
                    localStorage.removeItem("dynamicVehicleData");
                    navigationService.navigation("/Customers/ShoppingCart");
                } else {
                    thunkAPI.dispatch(hidePreLoader());
                }
            }
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const removeLockforBooking = createAsyncThunk<boolean, { requestData: any }>(
    'facilityPreCart/removeLockforBooking',
    async ({ requestData }, thunkAPI) => {
        try {
            if (requestData.isTimeBase) {
                const response = await preCartApiRequest.removeLockforTimeBaseBooking(requestData);
            } else {
                const response = await preCartApiRequest.removeLockforBooking(requestData);
            }
            return true;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const facilityPreCartSlice = createSlice({
    name: 'facilityPreCart',
    initialState,
    reducers: {
        setFacilityPreCartDetail: (state, action) => {
            state.facilityPreCartDetail = action.payload;
        },
    },
})

export const { setFacilityPreCartDetail } = facilityPreCartSlice.actions;