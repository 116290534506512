import { createSlice } from "@reduxjs/toolkit";

interface TimerState {
    isShoppingCartTimerDisplay: boolean,
    shoppingCartTimerStartTime: any,
    shoppingCartTimerEndTime: any,
    shoppingCartExpireMinutes: number
}

export const getShoppingCartTimerData = () => {
    let isTimerDisplay = false;
    let startTime = null;
    let endTime = null;
    let cartDetail = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart") || "{}") : null;
    let shoppingCartExpireMinutes: any = localStorage.getItem("shoppingCartExpireMinutes");
    shoppingCartExpireMinutes = shoppingCartExpireMinutes && parseInt(shoppingCartExpireMinutes) > 0 ? parseInt(shoppingCartExpireMinutes) : 0
    if (cartDetail && cartDetail.EndTime) {
        startTime = cartDetail.StartTime ? new Date(cartDetail.StartTime) : null;
        endTime = cartDetail.EndTime ? new Date(cartDetail.EndTime) : null;
        if (endTime && endTime.getTime() > new Date().getTime()) {
            isTimerDisplay = true;
        }
    }
    if (!isTimerDisplay && localStorage.getItem("cart")) {
        localStorage.removeItem("cart");
        localStorage.removeItem("shoppingCartKey");
        localStorage.removeItem("cartCount");
        localStorage.removeItem("shoppingCartExpireMinutes");
        startTime = null;
        endTime = null;
        shoppingCartExpireMinutes = 0;
    }
    return { isTimerDisplay, startTime, endTime, shoppingCartExpireMinutes };
}

const shoppingCartData = getShoppingCartTimerData();

const initialState: TimerState = {
    isShoppingCartTimerDisplay: shoppingCartData.isTimerDisplay,
    shoppingCartTimerStartTime: shoppingCartData.startTime,
    shoppingCartTimerEndTime: shoppingCartData.endTime,
    shoppingCartExpireMinutes: shoppingCartData.shoppingCartExpireMinutes
}


export const timerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        setShoppingCartTimer: (state, action) => {
            state.isShoppingCartTimerDisplay = action.payload.isShoppingCartTimerDisplay;
            state.shoppingCartTimerStartTime = action.payload.shoppingCartTimerStartTime;
            state.shoppingCartTimerEndTime = action.payload.shoppingCartTimerEndTime;
        },
        setShoppingCartExpireMinutes: (state, action) => {
            state.shoppingCartExpireMinutes = action.payload;
        }
    },
})

export const { setShoppingCartTimer, setShoppingCartExpireMinutes } = timerSlice.actions;